import React, { useState, useCallback, useEffect } from 'react'
import { styled } from '@mui/material'
import {
  InvoicePayerViewModel,
  PaymentInstallmentFrequencyEnum,
} from '@rsmus/ecp-financeservice'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import {
  getPayableAmount,
  getInvoicePayments,
  setInvoicePayments,
  setScheduledInstallments,
  ScheduledInstallments,
  InvoicesWithPayments,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import {
  getSelectedBankAccount,
  getSelectedCreditCard,
  getSelectedPayer,
  getSelectedPaymentMethod,
  setConfirmPaymentError,
} from '../../../store/invoices/paymentInfoSlice'
import api from '../../../api'
import PaymentMethod from './PaymentMethod'
import PaymentDate from './PaymentDate'
import Payer from './PaymentComponents/Payer'
import TermsAndConditionsDialog from './TermsAndConditionsDialog'
import Spinner from '../../forms/Spinner'
import AccountInfoSubText from './PaymentComponents/AccountInfoSubText'

const RowArea = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexBasis: 'auto',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  [theme.breakpoints.only('mobile')]: {
    flexDirection: 'column',
    marginTop: '1rem',
  },
}))

interface PaymentTypeScheduledProps {
  formSubmitCount: number
  payerChange: (payer: InvoicePayerViewModel | undefined) => void
  sessionCreating: () => void
  sessionChange: (sessionId: string, isError: boolean) => void
}

const PaymentTypeScheduled = ({
  formSubmitCount,
  payerChange,
  sessionCreating,
  sessionChange,
}: PaymentTypeScheduledProps) => {
  const { reset, getValues } = useFormContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const payableAmount = useSelector(getPayableAmount)
  const invoicePayments = useSelector(getInvoicePayments)
  const selectedBankAccount = useSelector(getSelectedBankAccount)
  const selectedCreditCard = useSelector(getSelectedCreditCard)
  const selectedPayer = useSelector(getSelectedPayer)
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openTermsDialog, setOpenTermsDialog] = useState<boolean>(false)

  const handleConfirmPayment = useCallback(async () => {
    setIsLoading(true)

    try {
      const scheduledDate = getValues('paymentDate') as Date
      const response = await api.finance.payment_SchedulePayments({
        amount: payableAmount,
        scheduledDate,
        clientPaymentMethodId:
          selectedPaymentMethod === 'BankAccount'
            ? selectedBankAccount ?? 0
            : selectedCreditCard ?? 0,
        payerName: selectedPayer?.name ?? '',
        invoices: invoicePayments.map((invoice) => ({
          invoiceId: invoice.id,
          invoiceNumber: invoice.invoiceNumber,
          amount: invoice.paidAmount,
        })),
        installmentFrequency: PaymentInstallmentFrequencyEnum.OneTime,
        numberOfPayments: 1,
      })

      const scheduledInstallments: ScheduledInstallments = {
        accountNumber: response.data?.accountNumber,
        installmentPlanId: response.data?.installmentPlanId ?? 0,
        installments: response.data?.installments,
        payer: selectedPayer?.name ?? '',
        paymentMethod: selectedPaymentMethod,
        installmentPlan: '',
      }

      const invoicesWithPayments: InvoicesWithPayments = invoicePayments.map(
        (invoice) => ({
          company: invoice.company,
          id: invoice.id,
          invoiceNumber: invoice.invoiceNumber,
          paidAmount: invoice.paidAmount,
          openAmount: invoice.openAmount,
          currency: invoice.currency,
          customerName: invoice.customerName,
          customerNumber: invoice.customerNumber,
          date: invoice.date,
          invoicePaidDate: invoice.invoicePaidDate,
          originalAmount: invoice.originalAmount,
          status: t('Invoicing.Scheduled'),
          scheduledPaymentType: invoice.scheduledPaymentType,
          scheduledDate: invoice.scheduledDate,
        }),
      )
      dispatch(setInvoicePayments(invoicesWithPayments))
      dispatch(setScheduledInstallments(scheduledInstallments))

      reset()
      setIsLoading(false)
      navigate('/invoicing/invoices/payment-success')
    } catch {
      dispatch(setConfirmPaymentError(true))
      setIsLoading(false)
    }
  }, [
    payableAmount,
    selectedPaymentMethod,
    selectedBankAccount,
    selectedCreditCard,
    invoicePayments,
    selectedPayer,
    getValues,
    dispatch,
    reset,
    navigate,
  ])

  // When the form is submitted, open the terms and conditions dialog.
  useEffect(() => {
    if (formSubmitCount > 0) {
      setOpenTermsDialog(true)
    }
  }, [formSubmitCount])

  return (
    <>
      <Spinner open={isLoading} />
      <RowArea>
        <PaymentDate
          labelKey="Invoicing.PaymentDate"
          errorKey="Invoicing.PaymentDateRequired"
        />
      </RowArea>
      <RowArea>
        <Payer payerChange={payerChange} />
      </RowArea>
      <AccountInfoSubText />
      <RowArea>
        <PaymentMethod
          paymentMethodType={selectedPaymentMethod}
          isScheduledPayment
          sessionCreating={sessionCreating}
          sessionChange={sessionChange}
        />
      </RowArea>
      <TermsAndConditionsDialog
        isAutomaticPayment
        open={openTermsDialog}
        onAccept={() => {
          setOpenTermsDialog(false)
          handleConfirmPayment()
        }}
        onClose={() => setOpenTermsDialog(false)}
        hasUserReadTerms={false}
      />
    </>
  )
}

export default PaymentTypeScheduled
