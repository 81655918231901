/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InvoicesSearchFilter } from '@rsmus/ecp-financeservice'
import { useLocation } from 'react-router-dom'
import {
  useGetInvoiceSearchFiltersQuery,
  useGetTotalOpenAmountQuery,
  useUpdateInvoiceSearchResultsMutation,
} from '../../../store/invoices/invoiceSearchService'
import {
  clearAllFilters,
  clearCategoryFilters,
  getInvoiceSelectedFilters,
  InvoiceSelectedFiltersState,
  setPageSize,
  toPageNumber,
  toNextPage,
  toPreviousPage,
  updateFilterValue,
  updateSearch as updateSearchFilter,
  amountRangeFilterMax,
  getPageInfo,
  updateSorting,
} from '../../../store/invoices/invoiceSelectedFiltersSlice'
import { setSelectedInvoices } from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import { RsmPaginationPageSizes } from '../../../rsmCoreComponents/components/RsmPagination/RsmPagination'

const transformFilters = (
  selectedFilters: InvoiceSelectedFiltersState,
): InvoicesSearchFilter =>
  ({
    pageNumber: selectedFilters.pageNumber,
    pageSize: selectedFilters.pageSize,
    searchString: selectedFilters.searchString,
    invoiceStatus: selectedFilters.invoiceStatus,
    invoiceStartDate: selectedFilters.invoiceStartDate,
    invoiceEndDate: selectedFilters.invoiceEndDate,
    invoiceAmountFrom: selectedFilters.invoiceAmountFrom,
    invoiceAmountTo:
      selectedFilters.invoiceAmountTo === amountRangeFilterMax
        ? 0
        : selectedFilters.invoiceAmountTo,
    currency: selectedFilters.currency,
    sortBy: selectedFilters.sortby,
    isSortAscending: selectedFilters.isSortAscending,
    clientIds: selectedFilters.clientId ? [+selectedFilters.clientId] : [],
  } as InvoicesSearchFilter)

// this HOOK should have all ACTIONS related to invoices search page

const useInvoicesSearch = () => {
  const location = useLocation()
  const pageInfo = useSelector(getPageInfo)
  const selectedFilters = useSelector(getInvoiceSelectedFilters)
  const [isSearchPending, setIsSearchPending] = useState<boolean>(false)
  const [isTextSearchPending, setIsTextSearchPending] = useState<boolean>(false)
  const dispatch = useDispatch()
  const {
    isSuccess: filterIsSuccess,
    isError: filterIsError,
    data: filterData,
    error: filterError,
  } = useGetInvoiceSearchFiltersQuery()

  const [
    updateSearch,
    {
      isLoading: invoicesIsLoading,
      isError: invoicesIsError,
      data: invoicesData,
      error: invoicesError,
    },
  ] = useUpdateInvoiceSearchResultsMutation()

  const submitSearch = useCallback(async () => {
    if ((isSearchPending || isTextSearchPending) && filterIsSuccess) {
      setIsSearchPending(false)
      setIsTextSearchPending(false)
      const filters = transformFilters(selectedFilters)
      try {
        await updateSearch(filters).unwrap()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e?.message !== 'canceled') {
          throw e
        }
      }
    }
  }, [
    isSearchPending,
    isTextSearchPending,
    filterIsSuccess,
    transformFilters,
    selectedFilters,
    setIsSearchPending,
    updateSearch,
  ])

  const requestSearch = useCallback(
    (search: boolean, textSearch: boolean) => {
      setIsSearchPending(search)
      setIsTextSearchPending(textSearch)
    },
    [setIsSearchPending, setIsTextSearchPending],
  )

  const setInvoicePageSize = useCallback(
    (pageSize: RsmPaginationPageSizes) => {
      dispatch(setPageSize(pageSize))
      requestSearch(true, false)
    },
    [dispatch, setPageSize, requestSearch],
  )

  const toPage = useCallback(
    (pageNumber: number) => {
      dispatch(toPageNumber(pageNumber))
      requestSearch(true, false)
    },
    [dispatch, toPageNumber, requestSearch],
  )

  const nextPage = useCallback(() => {
    dispatch(toNextPage())
    requestSearch(true, false)
  }, [dispatch, toNextPage, requestSearch])

  const previousPage = useCallback(() => {
    dispatch(toPreviousPage())
    requestSearch(true, false)
  }, [dispatch, toPreviousPage, requestSearch])

  const SortInvoiceData = useCallback(
    (sortBy: string, isAscending: 'asc' | 'desc') => {
      // Update the state or dispatch an action to handle sorting
      const ascending = isAscending === 'asc'
      dispatch(updateSorting({ sortBy, isAscending: ascending }))
      requestSearch(true, false)
    },
    [dispatch, requestSearch],
  )

  const changeFilterValue = useCallback(
    (filterValue: string) => {
      dispatch(updateFilterValue(filterValue))
    },
    [dispatch, updateFilterValue],
  )

  const clearFilters = useCallback(() => {
    dispatch(setSelectedInvoices([]))
    dispatch(clearAllFilters())
    requestSearch(true, false)
  }, [dispatch, setSelectedInvoices, clearAllFilters, requestSearch])

  const clearCategoryFilter = useCallback(
    (categoryValue: string) => {
      dispatch(setSelectedInvoices([]))
      dispatch(clearCategoryFilters(categoryValue))
      requestSearch(true, false)
    },
    [dispatch, setSelectedInvoices, clearCategoryFilters, requestSearch],
  )

  const changeFilterAndSearch = useCallback(
    (filterValue: string) => {
      dispatch(setSelectedInvoices([]))
      changeFilterValue(filterValue)
      requestSearch(true, false)
    },
    [dispatch, setSelectedInvoices, changeFilterValue, requestSearch],
  )

  const changeSearchFilterAndSearch = (searchString: string) => {
    if (searchString !== selectedFilters.searchString) {
      dispatch(updateSearchFilter(searchString))
      requestSearch(false, true)
    }
  }

  useEffect(() => {
    if (location.pathname === '/invoicing/invoices') {
      submitSearch()
    }
  }, [location.pathname, isSearchPending, isTextSearchPending, filterIsSuccess])

  return {
    filterIsSuccess,
    filterIsError,
    filterData,
    filterError,
    invoicesIsLoading,
    invoicesIsError,
    invoicesData,
    invoicesError,
    nextPage,
    previousPage,
    setInvoicePageSize,
    toPage,
    pageInfo,
    clearFilters,
    clearCategoryFilter,
    changeFilterValue,
    requestSearch,
    changeFilterAndSearch,
    changeSearchFilterAndSearch,
    SortInvoiceData,
  }
}

export default useInvoicesSearch
