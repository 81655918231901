import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  InvoicePayerViewModel,
  PaymentConfirmResponseViewModel,
  SchedulePaymentResponseViewModel,
  PayOnAccountConfirmResponseViewModel,
} from '@rsmus/ecp-financeservice'
import type { RootState } from '..'
import { InvoiceData } from './invoiceSearchService'
import { PaymentMethodType } from '../../types'

export type InvoicesWithPayments = (InvoiceData & { paidAmount: number })[]
export type ConfirmPaymentResponse =
  | (PaymentConfirmResponseViewModel & {
      payer: string
      paymentMethod: PaymentMethodType
    })
  | null

export type ScheduledInstallments =
  | (SchedulePaymentResponseViewModel & {
      payer: string
      paymentMethod: PaymentMethodType
      installmentPlan: string
    })
  | null

export type PayOnAccountPayment =
  | (PayOnAccountConfirmResponseViewModel & {
      payer: string
      paymentMethod: PaymentMethodType
      accountName: string | undefined
    })
  | null

export type PayOnAccountClientData = {
  name: string | undefined
  clientId: string
  isCreditCardEnabled: boolean | undefined
  totalOpenAmount: number
} | null

export type AutoPaySetup = {
  companyName: string
  payer: string
  paymentMethod: PaymentMethodType
  frequency: string | undefined
  startDate: string | undefined
  autoPaylimit: number | undefined
  accountNumber: string | undefined
  clientId: string | undefined
} | null

export type ImplementAutoPayLimitType = 'Yes' | 'No'

// Define a type for the slice state
interface InvoicesSelectedState {
  selectedInvoices: InvoiceData[]
  payingInvoices: InvoiceData[]
  invoicePayments: InvoicesWithPayments
  payableAmount: number
  paymentTransaction: ConfirmPaymentResponse
  invoicePayers: InvoicePayerViewModel[]
  isPartialInvoice: boolean
  scheduledInstallments: ScheduledInstallments
  isAccountInfoLoaded: boolean
  selectedAccInvoices: InvoiceData[]
  payOnAccountClient: PayOnAccountClientData
  payOnAccountPayment: PayOnAccountPayment
  scheduledPaymentCount: number
  autoPaySetup: AutoPaySetup
  implementAutoPayLimit: ImplementAutoPayLimitType
  isEditAutoPay: boolean
}

// Define the initial state using that type
const initialState: InvoicesSelectedState = {
  selectedInvoices: [] as InvoiceData[],
  payingInvoices: [] as InvoiceData[],
  invoicePayments: [] as InvoicesWithPayments,
  payableAmount: 0,
  paymentTransaction: null as ConfirmPaymentResponse,
  invoicePayers: [] as InvoicePayerViewModel[],
  isPartialInvoice: false,
  scheduledInstallments: null as ScheduledInstallments,
  isAccountInfoLoaded: false,
  selectedAccInvoices: [] as InvoiceData[],
  payOnAccountClient: null as PayOnAccountClientData,
  payOnAccountPayment: null as PayOnAccountPayment,
  scheduledPaymentCount: 0,
  autoPaySetup: null as AutoPaySetup,
  implementAutoPayLimit: 'No' as ImplementAutoPayLimitType,
  isEditAutoPay: false,
}

export const invoiceSelectedInvoicesSlice = createSlice({
  name: 'invoiceSelectedInvoices',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetAll: (state) => {
      state.selectedInvoices = []
      state.payingInvoices = []
      state.invoicePayments = []
      state.payableAmount = 0
      state.paymentTransaction = null as ConfirmPaymentResponse
      state.invoicePayers = []
      state.isPartialInvoice = false
      state.scheduledInstallments = null as ScheduledInstallments
      state.isAccountInfoLoaded = false
      state.selectedAccInvoices = []
    },
    setSelectedInvoices: (state, action: PayloadAction<InvoiceData[]>) => {
      state.selectedInvoices = action.payload
    },
    setPayingInvoices: (state, action: PayloadAction<InvoiceData[]>) => {
      state.payingInvoices = action.payload
    },
    setInvoicePayments: (
      state,
      action: PayloadAction<InvoicesWithPayments>,
    ) => {
      state.invoicePayments = action.payload
    },
    setPayableAmount: (state, action: PayloadAction<number>) => {
      state.payableAmount = action.payload
    },
    setPaymentTransaction: (
      state,
      action: PayloadAction<ConfirmPaymentResponse>,
    ) => {
      state.paymentTransaction = action.payload
    },
    setInvoicePayers: (
      state,
      action: PayloadAction<InvoicePayerViewModel[]>,
    ) => {
      state.invoicePayers = action.payload
    },
    setPartialAmount: (state, action: PayloadAction<boolean>) => {
      state.isPartialInvoice = action.payload
    },
    setScheduledInstallments: (
      state,
      action: PayloadAction<ScheduledInstallments>,
    ) => {
      state.scheduledInstallments = action.payload
    },
    setIsAccountInfoLoaded: (state, action: PayloadAction<boolean>) => {
      state.isAccountInfoLoaded = action.payload
    },
    setselectedAccInvoices: (state, action: PayloadAction<InvoiceData[]>) => {
      state.selectedAccInvoices = action.payload
    },
    setPayOnAccountClient: (
      state,
      action: PayloadAction<PayOnAccountClientData>,
    ) => {
      state.payOnAccountClient = action.payload
    },
    setPayOnAccountPayment: (
      state,
      action: PayloadAction<PayOnAccountPayment>,
    ) => {
      state.payOnAccountPayment = action.payload
    },
    setScheduledPaymentCount: (state, action: PayloadAction<number>) => {
      state.scheduledPaymentCount = action.payload
    },
    setAutoPaySetup: (state, action: PayloadAction<AutoPaySetup>) => {
      state.autoPaySetup = action.payload
    },
    setImplementAutoPayLimit: (
      state,
      action: PayloadAction<ImplementAutoPayLimitType>,
    ) => {
      state.implementAutoPayLimit = action.payload
    },
    setIsEditAutoPay: (state, action: PayloadAction<boolean>) => {
      state.isEditAutoPay = action.payload
    },
  },
})

export const { resetAll } = invoiceSelectedInvoicesSlice.actions

// Holds which invoices the user has selected via checkboxes on the invoice search page
// and can also be modified via the checkboxes on the invoice payment page
export const { setSelectedInvoices } = invoiceSelectedInvoicesSlice.actions
export const getSelectedInvoices = (state: RootState) =>
  state.invoiceSelectedInvoices.selectedInvoices

// Holds which invoices the user has originally selected to pay on the invoice search page.
// This is separate from the selectedInvoices state because the user may unselect some
// invoices on the invoice payment page, and we don't want to lose that information
export const { setPayingInvoices } = invoiceSelectedInvoicesSlice.actions
export const getPayingInvoices = (state: RootState) =>
  state.invoiceSelectedInvoices.payingInvoices

// Holds the combined states of selectedInvoices and payingInvoices in that it only contains
// the invoices that the user has selected to pay (from selectedInvoices) along with the payment
// amount for each invoice (from payingInvoices).
export const { setInvoicePayments } = invoiceSelectedInvoicesSlice.actions
export const getInvoicePayments = (state: RootState) =>
  state.invoiceSelectedInvoices.invoicePayments

export const { setPayableAmount } = invoiceSelectedInvoicesSlice.actions
export const getPayableAmount = (state: RootState) =>
  state.invoiceSelectedInvoices.payableAmount

export const { setPaymentTransaction } = invoiceSelectedInvoicesSlice.actions
export const getPaymentTransaction = (state: RootState) =>
  state.invoiceSelectedInvoices.paymentTransaction

export const { setInvoicePayers } = invoiceSelectedInvoicesSlice.actions
export const getInvoicePayers = (state: RootState) =>
  state.invoiceSelectedInvoices.invoicePayers

export const { setPartialAmount } = invoiceSelectedInvoicesSlice.actions
export const getPartialAmount = (state: RootState) =>
  state.invoiceSelectedInvoices.isPartialInvoice

export const { setScheduledInstallments } = invoiceSelectedInvoicesSlice.actions
export const getScheduledInstallments = (state: RootState) =>
  state.invoiceSelectedInvoices.scheduledInstallments

export const { setIsAccountInfoLoaded } = invoiceSelectedInvoicesSlice.actions
export const getIsAccountInfoLoaded = (state: RootState) =>
  state.invoiceSelectedInvoices.isAccountInfoLoaded

export const { setselectedAccInvoices } = invoiceSelectedInvoicesSlice.actions
export const getselectedAccInvoices = (state: RootState) =>
  state.invoiceSelectedInvoices.selectedAccInvoices

export const { setPayOnAccountClient } = invoiceSelectedInvoicesSlice.actions
export const getPayOnAccountClient = (state: RootState) =>
  state.invoiceSelectedInvoices.payOnAccountClient

export const { setPayOnAccountPayment } = invoiceSelectedInvoicesSlice.actions
export const getPayOnAccountPayment = (state: RootState) =>
  state.invoiceSelectedInvoices.payOnAccountPayment

export const { setScheduledPaymentCount } = invoiceSelectedInvoicesSlice.actions
export const getScheduledPaymentCount = (state: RootState) =>
  state.invoiceSelectedInvoices.scheduledPaymentCount

export const { setAutoPaySetup } = invoiceSelectedInvoicesSlice.actions
export const getAutoPaySetup = (state: RootState) =>
  state.invoiceSelectedInvoices.autoPaySetup

export const { setImplementAutoPayLimit } = invoiceSelectedInvoicesSlice.actions
export const getImplementAutoPayLimit = (state: RootState) =>
  state.invoiceSelectedInvoices.implementAutoPayLimit

export const { setIsEditAutoPay } = invoiceSelectedInvoicesSlice.actions
export const getIsEditAutoPay = (state: RootState) =>
  state.invoiceSelectedInvoices.isEditAutoPay

export default invoiceSelectedInvoicesSlice.reducer
